

const translation = {
    en:{
        appName:"Xryde",
        title: "Xryde",
        home:"home",
        service:"service",
        services:"services",
        event:"event",
        events:"events",
        help:"help",
        about:"about",
        contact:"contact",
        team:'team'

    },
    fr:{
        appName:"Xryde",
        title:"Xryde",
        home:"accueil",
        service:"Service",
        services:"Services",
        event:"evenement",
        help:"aide",
        about:"a propos",
        contact:"contact",
        team:'équipe'
    }
}

export default translation;