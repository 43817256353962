
import LanguageContextProvider from "../../contextLang";
import { Footer } from "../footers/Footer";
import { HomeHeader } from "../headers/home/HomeHeader";


export function HomeBodyProvider({children}) {

    return(<LanguageContextProvider>
        <HomeHeader/>
        {children}
        
        <Footer/>
    </LanguageContextProvider>)
}