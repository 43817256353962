

export const TeamComponent = () => {

    return(<>
    
<section>
	<div class="container">
        {/*  */}
		{/* Title */}
		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2 class="mb-0">Notre équipe</h2>
			</div>
		</div>

		<div class="row g-4 g-md-5">
			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="https://lh3.googleusercontent.com/a-/AD_cMMQ5HM0D3JZ0ty5IaCcRPMT8JDnxMKFHDDJ-TnWiA336IP8=s48-p" class="rounded-circle" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Yao Kevin</a></h5>
						<h6>Fondateur de Xryde</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="../../images/team/gningninri.jpg" class="rounded-circle" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Gningninri Cedric</a></h5>
						<h6>Ingenieur développeur d'application</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="assets/images/category/hotel/nearby/03.jpg" class="rounded-circle" alt="" />

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Moustapha</a></h5>
						<h6>Ingenieur développeur d'application</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="../../images/team/cedric.jpg" class="rounded-circle" alt="" />

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Yao Parfait</a></h5>
						<h6>Développeur d'application Full-stack</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}
		</div> {/* Row END */}
	</div>
</section>
    </>)
}