
import { useContext } from 'react';
import './homeHeader.css'
import translation from '../../../services/tools/translation/translation';
import { LanguageContext } from '../../../contextLang';
import { strUcFirst } from '../../../services/tools/conversion/StringConversion';
import { Link } from 'react-router-dom';

export const HomeHeader = () => {

	const { language } = useContext(LanguageContext);
	const { switchLanguage } = useContext(LanguageContext);

	return (<>
		<header class="navbar-light header-sticky">
			{/*  */}
			{/* Logo Nav START */}
			<nav class="navbar navbar-expand-xl">
				<div class="container">
					{/* Logo START */}
					<Link class="navbar-brand d-flex justify-content-center">
						<img class="light-mode-item navbar-brand-item logo-img" src="images/logo/logo.png" alt="logo" />
						<img class="dark-mode-item navbar-brand-item logo-img" src="images/logo/logo.png" alt="logo" />
						<h4 className='pt-4'>Xryde</h4>
					</Link>
					{/* Logo END */}

					{/* Responsive navbar toggler */}
					<button class="navbar-toggler ms-auto ms-sm-0 p-0 p-sm-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-animation">
							<span></span>
							<span></span>
							<span></span>
						</span>
						<span class="d-none d-sm-inline-block small">Menu</span>
					</button>


					{/* Main navbar START */}
					<div class="navbar-collapse collapse" id="navbarCollapse">
						<ul class="navbar-nav navbar-nav-scroll me-auto">

							{/* Nav item Listing */}
							<li class="nav-item ">
								<Link class="nav-link" to="/" id="home" >{strUcFirst(translation[language].home)}</Link>
							</li>
							<li class="nav-item ">
								<Link class="nav-link" to="/404-error" id="about" >{strUcFirst(translation[language].about)}</Link>
							</li>
							<li class="nav-item ">
								<Link class="nav-link" to="/404-error" id="event" >{strUcFirst(translation[language].team)}</Link>
							</li>
							<li class="nav-item ">
								<Link class="nav-link" to="/404-error" id="help" >{strUcFirst(translation[language].help)}</Link>
							</li>
							<li class="nav-item ">
								<Link class="nav-link" to="/404-error" id="contact" >{strUcFirst(translation[language].contact)}</Link>
							</li>

							{/* Nav item link*/}
							<li class="nav-item dropdown">
								<Link class="nav-link" to="/404-error" id="advanceMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-h"></i>
								</Link>
								<ul class="dropdown-menu min-w-auto" data-bs-popper="none">
									<li>
										<Link class="dropdown-item" to="/404-error">
											<i class="text-warning fa-fw bi bi-download me-2"></i>Telecharger l'application Mobile
										</Link>
									</li>
									<li>
										<Link class="dropdown-item" to="/404-errorocs/index.html">
											<i class="text-danger fa-fw bi bi-card-text me-2"></i>Guide d'utilisation
										</Link>
									</li>
									<li> <hr class="dropdown-divider" /></li>
									<li>
										<Link class="dropdown-item" to="/404-errortl/index.html">
											<i class="text-info fa-fw bi bi-toggle-off me-2"></i>Comment devenir coonducteur?
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
					{/* Main navbar END */}
					<ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">

				{/* Search */}
				
				<li class="dropdown nav-item">
							<Link class="nav-link small pb-2" to="/404-error" role="button" id="languageDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="bi bi-globe fa-fw me-2"></i>Langue</Link>
							<ul class="dropdown-menu dropdown-animation dropdown-menu-end min-w-auto" aria-labelledby="languageDropdown">
								<li> <button class="dropdown-item me-4" to="/404-error" onClick={() => switchLanguage("fr")}><img class="fa-fw me-2" src="assets/images/flags/fr.svg" alt="" />Français</button> </li>
								<li> <button class="dropdown-item me-4" to="/404-error" onClick={() => switchLanguage("en")}><img class="fa-fw me-2" src="assets/images/flags/uk.svg" alt="" />English</button> </li>
							</ul>
						</li>

				{/* Sign In button */}
				<li class="nav-item btn-mr">
					<Link to="/login" class="btn btn-sm btn-primary mb-0"><i class="fa-solid fa-user me-sm-2 "></i><span class="d-none d-sm-inline">Connexion</span></Link>
				</li>
				<li class="nav-item me-2">
					<Link to="/register" class="btn btn-sm btn-secondary mb-0"><i class="fa-solid fa-right-to-bracket me-sm-2"></i><span class="d-none d-sm-inline">S'inscrire</span></Link>
				</li>
				{/* Dark mode options START */}
				<li class="nav-item dropdown">
					<button class="btn btn-link text-warning p-0 mb-0" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle-half theme-icon-active fa-fw" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
						<use to="#"></use>
					</svg>
					</button>

					<ul class="dropdown-menu min-w-auto dropdown-menu-end" aria-labelledby="bd-theme">
						<li class="mb-1">
							<button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="light">
								<svg width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
									<use to="#"></use>
								</svg>Jour
							</button>
						</li>
						<li class="mb-1">
							<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon-stars-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"></path>
									<path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path>
									<use to="#"></use>
								</svg>Nuit
							</button>
						</li>
						<li>
							<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
									<use to="#"></use>
								</svg>Auto
							</button>
						</li>
					</ul>
				</li>
				{/* Dark mode options END */}
			</ul>

				</div>
			</nav>
			{/* Logo Nav END */}
		</header>
	</>)
}