import logo from './logo.svg';
import './App.css';

import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HomePage } from './pages/home/HomePage';
import { LoginUserPage } from './pages/auth/login/LoginUserPage';
import { RegisterUserPage } from './pages/auth/register/RegisterUserPage';
import { Error404Page } from './pages/error/Error404Page';
import { PrivacyPage } from './pages/policy/PrivacyPage';

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          
          <Route path='/*' element={<HomePage />} ></Route>
          <Route path='/login' element={<LoginUserPage />} ></Route>
          <Route path='/register' element={<RegisterUserPage />} ></Route>
          <Route path='/privacy' element={<PrivacyPage />} ></Route>
          <Route path='/404-error' element={<Error404Page />} ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
