import React from 'react'
import { HomeBodyProvider } from '../../layout/bodies/HomeBodyProvider'

export const PrivacyPage = () => {
    return (
        <HomeBodyProvider children={<>
            <section className="pt-4 pt-lg-5">
                <div className="container">
                    {/* Title */}
                    <div className="row mb-4">
                        <div className="col-12 text-center">
                            <h1 className="fs-2 mb-0">Politique de confidentialité</h1>
                            {/* Image */}
                            <img
                                src="assets/images/about/03.jpg"
                                className="h-lg-400px rounded-3 mt-4"
                                alt=""
                            />
                        </div>
                    </div>
                    {/* Content START */}
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="vstack gap-3">
                                {/* Card item START */}
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Introduction</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>
                                            Cette politique de confidentialité (la "Politique") décrit comment Xryde (l'"Application") recueille, utilise et partage vos informations personnelles lorsque vous utilisez l'Application notre application mobile de covoiturage..
                                        </p>
                                        <p>Nous, XrydeSharing, nous engageons à protéger la vie privée de nos utilisateurs.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Définitions</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <ul className="mt-4 mb-0">
                                            <li>
                                                <b>Informations personnelles</b>  désigne toute information qui peut être utilisée pour identifier directement ou indirectement un individu, telles que le nom, l'adresse e-mail, le numéro de téléphone, l'adresse postale, les informations de paiement et les informations de profil.
                                            </li>
                                            <li>
                                                <b>Données d'utilisation</b> désigne des informations sur la façon dont vous utilisez l'Application, telles que les trajets que vous effectuez, les conducteurs avec lesquels vous voyagez et les notes que vous attribuez.
                                            </li>
                                            <li>
                                                <b>Tiers</b> désigne toute personne ou entité autre que [Nom de l'application] ou ses sociétés affiliées.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Conditions de collecte d'informations personnelles</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous recueillons des informations personnelles auprès de vous lorsque vous :</p>
                                        <ul className="list-group list-group-borderless mb-2">
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Créez un compte sur l'Application
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Connectez-vous à l'Application via un réseau social
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Effectuez un trajet en tant que conducteur ou passager
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Nous contactez pour obtenir de l'aide ou nous envoyez des commentaires
                                            </li>
                                        </ul>
                                        <p>Nous pouvons également recueillir des informations personnelles auprès de tiers, tels que des sociétés de vérification des antécédents et des fournisseurs de paiement.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Informations que Nous Collectons</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Informations fournies par l'utilisateur</p>
                                        <ul className="mt-0 mb-4">
                                            <li>
                                                <b>Informations de compte :</b> Lors de la création d'un compte, nous pouvons collecter votre nom, adresse e-mail, numéro de téléphone, et un mot de passe.
                                            </li>
                                            <li>
                                                <b>Profil utilisateur :</b> Vous pouvez fournir des informations supplémentaires comme votre photo, une biographie, et vos préférences de covoiturage.
                                            </li>
                                            <li>
                                                <b>Informations de paiement :</b> Pour traiter les paiements, nous collectons des informations de carte bancaire via un fournisseur de services de paiement sécurisé.
                                            </li>
                                        </ul>
                                        <p>Informations collectées automatiquement</p>
                                        <ul className="mt-0 mb-4">
                                            <li>
                                                <b>Données de localisation :</b> Nous collectons votre localisation GPS pour faciliter la correspondance avec des trajets de covoiturage.
                                            </li>
                                            <li>
                                                <b>Données d'utilisation :</b> Nous recueillons des informations sur votre utilisation de l'Application, telles que les pages visitées, les fonctionnalités utilisées, et la durée des sessions.
                                            </li>
                                            <li>
                                                <b>Données de l'appareil :</b> Nous collectons des informations sur votre appareil mobile, y compris le modèle, le système d'exploitation, et les identifiants uniques.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Utilisation des Informations</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous utilisons les informations que nous collectons pour :</p>
                                        <ul className="list-group list-group-borderless mb-2">
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Vous fournir l'Application et ses services
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Vérifier votre identité et prévenir les fraudes
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Vous contacter au sujet de votre compte et de vos trajets
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Améliorer l'Application et ses services
                                            </li>
                                            <li className="list-group-item d-flex">
                                                <i className="bi bi-check-circle-fill me-2" />
                                                Vous proposer des offres et des promotions
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Partage des informations personnelles</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous pouvons partager vos informations personnelles avec des tiers aux fins suivantes :</p>
                                        <ul className="mt-4 mb-0">
                                            <li>
                                                Pour vous fournir l'Application et ses services, tels que le jumelage avec des conducteurs ou des passagers
                                            </li>
                                            <li>
                                                Pour vérifier votre identité et prévenir les fraudes
                                            </li>
                                            <li>
                                                Pour nous conformer à la loi ou à une assignation à comparaître
                                            </li>
                                            <li>
                                                Pour protéger nos droits ou ceux de tiers
                                            </li>
                                        </ul>
                                        <p>Nous pouvons également partager vos informations personnelles avec des fournisseurs de services tiers qui nous aident à exploiter l'Application, tels que des fournisseurs d'hébergement, de traitement des paiements et de marketing.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Sécurité des informations personnelles</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous prenons des mesures de sécurité physiques, techniques et administratives pour protéger vos informations personnelles contre la perte, le vol, l'utilisation abusive, la divulgation non autorisée, l'accès, la modification ou la destruction.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Vos choix</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Vous pouvez choisir de ne pas nous fournir certaines informations personnelles, mais cela peut limiter votre capacité à utiliser certaines fonctionnalités de l'Application.</p>
                                        <p>Vous pouvez également nous demander de :</p>
                                        <ul className="mt-0 mb-3">
                                            <li>
                                                Accéder à vos informations personnelles
                                            </li>
                                            <li>
                                                Corriger ou mettre à jour vos informations personnelles
                                            </li>
                                            <li>
                                                Supprimer vos informations personnelles
                                            </li>
                                            <li>
                                                Cesser de traiter vos informations personnelles
                                            </li>
                                        </ul>
                                        <p>Vous pouvez exercer ces droits en nous contactant à l'adresse contact@xrydesharing.ci.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Conservation des données</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous conserverons vos informations personnelles aussi longtemps que nécessaire pour les fins pour lesquelles elles ont été collectées, ou pour se conformer à nos obligations légales.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Modifications de la politique</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Nous pouvons modifier cette politique de temps à autre. Nous pouvons mettre à jour cette politique de temps à autre. Nous vous notifierons de tout changement significatif via l'Application ou par d'autres moyens appropriés. La politique modifiée entrera en vigueur dès sa publication sur l'Application.</p>
                                    </div>
                                </div>
                                <div className="card bg-transparent p-0">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Politique de suppression de compte</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>
                                            <b>Conditions de suppression de compte</b>
                                        </p>
                                        <div className="bg-warning bg-opacity-10 rounded-2 p-3 mb-4">
                                            <p className="text-danger mb-0">
                                                Vous pouvez supprimer votre compte à tout moment pour quelque raison que ce soit. Toutefois, nous vous demandons de prendre en compte les points suivants avant d'annuler votre compte :
                                            </p>
                                            <ul className="mt-0 mb-2">
                                                <li>
                                                    <b>Trajets en cours :</b> Assurez-vous de finaliser tous les trajets en cours avant de demander l'annulation de votre compte.
                                                </li>
                                                <li>
                                                    <b>Paiements en attente :</b> Vérifiez qu'il n'y a pas de paiements en attente ou de litiges à résoudre.
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            Vous pouvez en suivant les étapes suivantes :
                                        </p>
                                        <ul className="mt-0 mb-3">
                                            <li>
                                                1. Ouvrez l'Application et connectez-vous à votre compte.
                                            </li>
                                            <li>
                                                2. Accédez aux paramètres de votre compte.
                                            </li>
                                            <li>
                                                3. Sélectionnez l'option "Supprimer le compte".
                                            </li>
                                            <li>
                                                4. Suivez les instructions à l'écran pour confirmer la suppression de votre compte.
                                            </li>
                                        </ul>
                                        <p>
                                        <b>Conséquences de la suppression de votre compte</b>
                                        </p>
                                        <p>Une fois que vous aurez supprimé votre compte, il sera définitivement supprimé, ainsi que toutes les informations personnelles qui lui sont associées. Vous ne pourrez plus utiliser l'Application ni ses services.</p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Remboursements</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>
                                        Si vous avez du credit sur votre compte au moment de sa suppression, vous ne serez pas remboursé.
                                        </p>
                                    </div>
                                </div>
                                <div className="card p-0 bg-transparent">
                                    {/* Card header */}
                                    <div className="card-header border-bottom bg-transparent px-0">
                                        <h4 className="card-title mb-0">Contact</h4>
                                    </div>
                                    {/* Card body */}
                                    <div className="card-body px-0">
                                        <p>Pour toute question ou demande concernant cette Politique de Confidentialité, veuillez nous contacter à :</p>
                                        <ul className="mt-3 mb-4">
                                            <li>
                                                <b>E-mail : </b>contact@xrydesharing.ci
                                            </li>
                                            <li>
                                                <b>Téléphone : </b>+225 05 76 75 92 63
                                            </li>
                                            <li>
                                                <b>Adreese :</b> Abidjan, Cocody
                                            </li>
                                        </ul>
                                        <p>En utilisant notre Application, vous acceptez les termes de cette Politique de Confidentialité.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Content END */}
                </div>
            </section>
        </>} />

    )
}
